.select-size input {
    display: none;
}

.select-size label {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #ddd;
    line-height: 50px;
    cursor: pointer
}

#m:checked~label[for="m"],
#t:checked~label[for="t"],
#th:checked~label[for="th"],
#w:checked~label[for="w"],
#s:checked~label[for="s"],
#su:checked~label[for="su"],
#f:checked~label[for="f"] {
    background: #ee4d1a;
    color: #ffffff;
}

.ant-picker-dropdown{
    z-index: 999999 !important;
}