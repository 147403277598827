body {
  font-family: Roboto, sans-serif;
}

a {
  text-decoration: none;
}

.f-500 {
  font-weight: 500;
}

.error {
  color: red;
  font-size: 12px;
}

/* LOG IN PAGE */

.content {
  padding: 12rem 0;
}

@media (max-width: 991.98px) {
  .content .bg {
    height: 500px;
  }

  .content {
    padding: 7rem 0;
  }
}

.content .contents,
.content .bg {
  width: 50%;
}

@media (max-width: 1199.98px) {

  .content .contents,
  .content .bg {
    width: 100%;
  }

  .avatar {
    display: none;
  }
}

.float-right {
  float: right;
}

.bx-shadow {
  box-shadow: rgba(0, 0, 0, 5%) 0px 5px 15px;
}

.theme-bg {
  background-color: #0b5ed7
}

.theme-color {
  color: #0b5ed7
}

.avatar {
  color: #fff;
  margin: 0 auto 30px;
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 9;
  padding: 7px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 62px;
}

.profile-drop:hover {
  cursor: pointer;
}

.mui-modal .MuiDialogTitle-root {
  color: #fff;
  margin: 0;
  padding: 16px;
  background-color: #269abc;
}

.mui-modal .MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: auto;
  padding-top: 10px !important;
}

.table-img {
  width: 50px;
  height: 50px;
  position: relative;
  padding: 3px;
}

.bordered-img-table {
  float: left;
  width: 52px;
  border: 1px #dad7d7 solid;
  height: 52px;
  position: relative;
  margin-right: 8px;
  border-radius: 10px;
  margin-bottom: 8px;
}

.mui-modal.large-dialog .MuiDialog-paper {
  width: 600px;
  height: 450px;
}

.mui-modal.x-large-dialog .MuiDialog-paper {
  width: 1000px;
  height: 600px;
}

.mui-modal.xx-large-dialog .MuiDialog-paper {
  width: 1050px;
  height: 800px;
  max-width: 1050px;

}

a:hover{
  cursor: pointer;
}

.list{
  overflow-y: scroll;
}

@media only screen and (max-width:767px){

  .mui-modal.xx-large-dialog .MuiDialog-paper {
    width: auto;
    height: auto;
    max-width: auto;
  
  }
}